export const S3_URL = 'https://cmz-account-files.s3.eu-west-1.amazonaws.com';
export const API_URL = 'https://platform.api.plugger.ai';
export const GET_TRANSLATIONS = '/get/translations';
export const LOGIN = 'user/login';
export const REGISTER = 'user/register';
export const ACTIVATION = 'user/activation';
export const USER_GET_PROFILE = '/user/profile/get';
export const USER_UPDATE_PROFILE = '/user/profile/update';
export const USER_CHANGE_PASSWORD = '/user/password/change';
export const USER_REFRESH_TOKEN = '/user/token/refresh';
export const USER_EMAIL_PREFERENCES = '/user/email/preferences';
export const SESSION_START = '/session/start';
export const ACCOUNT_CREATE = '/account/profile/create';
export const ACCOUNT_DELETE = '/user/delete';
export const ACCOUNT_NAME_CHANGE = '/account/name/update';
export const ACCOUNT_GET_PROFILE = '/account/profile/get';
export const ACCOUNT_BILLING_LIST = '/account/billing/list';
export const ACCOUNT_SWITCH = '/account/profile/switch';
export const ACCOUNT_PAYMENT_CHECK = '/account/payment/check';
export const ACCOUNT_PACKAGE_GET = '/account/package/get';
export const ACCOUNT_REVENUE = '/account/revenue';
export const SUBSCRIPTION_CANCEL = '/account/subscription/cancel';
export const SUBSCRIPTION_LIST = '/subscription/list';
export const SUBSCRIPTION_UPDATE = '/account/subscription/update ';
export const RTSP_FORMAT_LIST = '/camera/rtsp-format/list';
export const PAYMENT_PORTAL_GET = '/payment/portal/get';
export const PAYMENT_LINK_GET = '/payment/link/get';
export const PAYMENT_LINK_LIST = '/payment/link/list';
export const PAYMENT_SUBSCRIPTION_LIST = '/payment/subscription/list';
export const PAYMENT_SESSION_CHECK = '/payment/session/check';
export const CHARGE_NOW = '/payment/charge';
export const USER_REGISTER = '/user/register';
export const USER_LIST = '/account/user/list';
export const USER_DELETE = '/account/user/delete';
export const USER_JOB = '/user/job';
export const FORGOT_PASSWORD = '/user/password/forget';
export const FILE_CREATE = '/file/create';
export const FILE_DELETE = '/file/delete';
export const FILE_GENERATE = '/file/generate';
export const DEFAULT_TEST_IMAGE_LIST = '/test-image/list';
export const TEST_IMAGE_LIST = '/test-image/list';
export const TEST_IMAGE_CREATE = '/test-image/create';
export const APPLICATION_BUILDER_LIST = '/application/builder/list';
export const APPLICATION_BUILDER_ALL_LIST = '/application/builder/all/list';
export const SEARCH_DATABASE_UPDATE = '/application/builder/search/update';
export const APPLICATION_BUILDER_CONFIGURATION_UPDATE =
  '/application/builder/configuration/update';
export const SAFETENSOR_MODEL_CREATE = '/application/builder/duplicate-from';
export const APPLICATION_BUILDER_DESCRIPTION_UPDATE =
  '/application/builder/description/update';
export const APPLICATION_LIST = '/application/list';
export const APPLICATON_BUILDER_CREATE = '/application/builder/create';
export const APPLICATON_BUILDER_GET = '/application/builder/get';
export const APPLICATON_BUILDER_DELETE = '/application/builder/delete';
export const APPLICATON_BUILDER_UPDATE = '/application/builder/update';
export const APPLICATION_BUILDER_IS_PUBLIC_UPDATE =
  '/application/builder/is-public/update';
export const APPLICATION_BUILDER_STATUS_UPDATE =
  '/application/builder/status/update';
export const CIVIT_AI_DUBLICATE_MODEL = '/application/builder/duplicate-from';
export const AI_FRAMEWORK_LIST = '/ai-framework/list';
export const APPLICATON_BUILDER_LIST_BY_ACCOUNT =
  '/application/builder/list-by-account';
export const MODEL_LIST = '/model/list';
export const MODEL_CREATE = '/model/create';
export const MODEL_UPDATE = '/model/update';
export const MODEL_DELETE = '/model/delete';
export const CONFIGURATION_INPUT_CREATE = 'configuration-input/create';
export const CONFIGURATION_INPUT_DELETE = 'configuration-input/delete';
export const CONFIGURATION_INPUT_LIST = 'configuration-input/list';
export const MODEL_LABEL_GET = '/model/label/get';
export const INPUT_OUTPUT_TYPE_LIST = '/input-output-type/list';
export const APPLICATON_BUILDER_CATEGORY_LIST =
  'application-builder-category/list';
export const APPLICATON_BUILDER_SCHEMA_GET = '/application-builder-schema/get';
export const APPLICATON_BUILDER_SCHEMA_CREATE =
  '/application-builder-schema/create';
export const APPLICATON_BUILDER_SCHEMA_DELETE =
  '/application-builder-schema/delete';
export const CATEGORY_CREATE = '/application-builder-category/create';
export const APPLICATON_BUILDER_CATEGORY_CREATE =
  '/application-builder-category-relation/create';
export const APPLICATION_BUILDER_CATEGORY_DELETE =
  '/application-builder-category-relation/delete';
export const GITHUB_INFORMATION = '/user/github-information/get';
export const MIXPNAEL_USERS = 'admin/user/list';
export const SEND_MAIL_URL =
  'https://f5cgmopvtz5knxhyr2qn6u4cx40feqgl.lambda-url.eu-west-1.on.aws/';
export const WEBFLOW_PUBLISH = '/application/builder/webflow/create';
export const APPLICATION_BUILDER_SERVICE_STATUS_GET =
  '/application/builder/service/status/get';
export const EXAMPLE_TERMS = '/chat/example/get';
export const CHAT_START = '/chat/start';
export const CHAT_ANONYM_START = '/chat/anonym/create';
export const CHAT_LIST = '/chat/list';
export const CHAT_DELETE = '/chat/delete';
export const CHAT_GET = '/chat/history/get';
export const CHAT_STATUS_GET = '/chat/status/get';
export const CHAT_NAME_UPDATE = '/chat/name/update';
export const CHAT_BOOKMARK_UPDATE = '/chat/bookmark/update';
export const CHAT_MESSAGE_LIKE = '/chat/message/like';
export const CHAT_BACKGROUND_MESSAGE = '/chat/message/background/create';
export const REMOVE_WATERMARK = '/chat/remove-watermark';
export const PROMPT_LIST = '/prompt/list';
export const PROMPT_CREATE = '/prompt/create';
export const PROMPT_DELETE = '/prompt/delete';
export const WELCOME_PAGE_USER_SEGMENT = '/user/segment';
export const BOOKMARK_GET = '/bookmark/get';
export const BOOKMAKR_CREATE = '/bookmark/create';
export const BOOKMAKR_DELETE = '/bookmark/delete';
export const BOOKMAKR_LIST = '/bookmark/list';

export const CREATE_FAVORITE = '/favorite/create';
export const DELETE_FAVORITE = '/favorite/delete';
export const LIST_FAVORITE = '/favorite/list';
export const GET_FAVORITE = '/favorite/get';
export const MAKE_PUBLIC_FAVORITE = '/favorite/make-public';
export const LIST_PUBLIC_FAVORITE = '/favorite/list/public';
export const PUBLIC_GALLERY = 'favorite/inspiration/list';
export const PUBLIC_GALLERY_SEARCH = 'favorite/inspiration/search';
export const PUBLIC_ITEM = 'favorite/inspiration/get';
export const PUBLIC_CREATE = 'favorite/inspiration/create';

export const TOOL_GET = '/tool/get';
export const GET_SUBSTYLES = "/tool/style/get"
export const GET_CUSTOM_SUBSTYLES = "/training/list"

export const ASSISTANT_RUN =
  'https://6naym3ofr4yy3f7qoocfkgzkgq0csiyk.lambda-url.eu-west-1.on.aws/';

export const GET_TOTAL_TASK = '/report/total-task';

export const QUESTION_GET = '/question/get';

export const BRAND_SAVE = '/brand/save';
export const BRAND_LIST = '/brand/list';
export const BRAND_DELETE = '/brand/delete';

export const TYPE_SELECTION_LIST = '/selection/list';
export const TYPE_SELECTION_SEARCH = '/selection/search';

export const ADMIN_CHAT_LIST = '/admin/chat/list'
export const ADMIN_TEMPLATE_LIST = '/admin/template/list'

export const APPSUMO_LICENSE_GET = '/appsumo/license'

export const MASK_CREATE = '/mask/create';

export const DOWNLOAD_ZIP = '/export/as-zip';

export const PRODUCT_SAVE = '/product/save';
export const PRODUCT_LIST = '/product/list';
export const PRODUCT_GET = '/product/get';
export const PRODUCT_DELETE = '/product/delete';

export const FONT_LIST = '/font/list';
export const FONT_LIST_MY = '/font/list/my';
export const FONT_SAVE = '/font/save';
export const FONT_DELETE = '/font/delete';
export const FONT_GET = '/font/get';

export const IMAGE_SAVE = '/image/metadata/save';
export const IMAGE_GET = '/image/metadata/get';
export const IMAGE_TO_PROMPT = '/image/prompt';

export const CUSTOMIZE_GET = '/customize/get';
export const CUSTOMIZE_UPDATE = '/customize/update';
export const CUSTOMIZE_UPLOAD = '/customize/upload';

export const MASK_WRAPPER = '/mask/wrapper';

export const ONBOARDING_LIST = '/onboarding/list'
export const ONBOARDING_GET = '/onboarding/get'
export const ONBOARDING_CREATE = '/onboarding/create'

export const API_CALLS_GET = '/account/api-calls'

export const TRAINING_CREATE = '/training/create'

export const CREATE_COLLECTION = '/collection/create'
export const LIST_COLLECTION = '/collection/list'